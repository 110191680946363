<template>
	<f-card>
		<v-data-table
			:search="search"
			:headers="headers"
			:items="list"
			@click:row="edit">
			<template v-slot:top>
				<v-row class="d-flex align-end flex-column">
					<v-col cols="4">
						<v-text-field
							v-model="search"
							label="Search"
							prepend-inner-icon="mdi-magnify" />
					</v-col>
				</v-row>
			</template>
		</v-data-table>
	</f-card>
</template>

<script>
import { SETTINGS_ROUTES } from "./../../../router/settings.js";
import { loadWords } from "./../../../services/verticals";

export default {
	data() {
		return {
			search: "",
			headers: [
				{
					text: this.$t("labels.keywords"),
					align: "start",
					sortable: true,
					value: "name",
				},
				{
					text: this.$t("labels.vertical"),
					align: "start",
					sortable: true,
					value: "title",
				},
				{
					text: this.$t("labels.createdAt"),
					value: "created_at",
					align: "end",
				},
			],
		};
	},
	computed: {
		list() {
			return this.$store.state.verticals.list;
		},
	},
	created() {
		this.getData();
	},
	methods: {
		async getData() {
			loadWords();
		},
		edit({ id }) {
			this.$router.push({
				name: SETTINGS_ROUTES.VERTICALS_REL_WORDS_EDIT,
				params: { id },
			});
		},
		goToUploads() {
			this.$router.push({
				name: SETTINGS_ROUTES.VERTICALS_REL_UPLOAD,
			});
		},
		goToCrud() {
			this.$router.push({
				name: SETTINGS_ROUTES.VERTICALS_REL_WORDS_LIST,
			});
		},
	},
};
</script>
