<template>
	<div>
		<global-menu />

		<f-default-header
			:title="$t('pages.settings.subtitle.keywords')"
			:subtitle="
				$t('pages.settings.verticalActivityRel.subtitle.configRelation')
			" />

		<div
			v-html="$t('pages.settings.verticalActivityRel.help.keywords')"
			class="status-alert"></div>

		<words-list />

		<f-actions-btn-holder>
			<v-btn color="primary" elevation="0" large @click="newWord">
				{{ $t("labels.btnNew") }}
			</v-btn>
		</f-actions-btn-holder>
	</div>
</template>

<script>
import GlobalMenu from "../../../components/settings/verticalActivityRel/GlobalMenu.vue";
import WordsList from "../../../components/settings/verticalActivityRel/WordsList.vue";
import { SETTINGS_ROUTES } from "../../../router/settings";

export default {
	components: {
		GlobalMenu,
		WordsList,
	},
	methods: {
		newWord() {
			this.$router.push({
				name: SETTINGS_ROUTES.VERTICALS_REL_WORDS_CREATE,
			});
		},
	},
};
</script>

<style lang="scss">
.status-alert {
	background-color: #cccccc;
	border-radius: 3px;
	margin-bottom: 24px;
	padding: 12px 24px;
	font-weight: normal;
}
</style>
